import DOMPurify from 'dompurify';

export class UpperCaseValueConverter {
    /**
     * @param value
     *
     * @returns {*}
     */
    toView(value) {
        return value ? value.toUpperCase() : null;
    }
}

export class LowerCaseValueConverter {
    /**
     * @param value
     *
     * @returns {*}
     */
    toView(value) {
        return value ? value.toLowerCase() : null;
    }
}

export class HierarchyToTextValueConverter {
    /**
     * @param node
     *
     * @returns {*}
     */
    toView(node) {
        let text = node.name;

        for (let i = 0; i < node.children.length; i++) {
            text = text + ' > ' + this.toView(node.children[i]);
        }

        return text;
    }
}

export class HumanReadableFilesizeValueConverter {
    /**
     * @param bytes
     * @param places
     * @param si
     *
     * @returns {string}
     */
    toView(bytes, places = 2, si = true) {
        let thresh = si ? 1000 : 1024;

        if (Math.abs(bytes) < thresh) {
            return bytes + ' B';
        }

        let units = si
            ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
            : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

        let u = -1;

        do {
            bytes /= thresh;
            ++u;
        } while (Math.abs(bytes) >= thresh && u < units.length - 1);

        return bytes.toFixed(places) + ' ' + units[u];
    }
}

export class PreserveLineBreaksValueConverter {
    /**
     * @param text
     *
     * @returns string
     */
    toView(text) {
        return text ? text.replace(/(?:\r\n|\r|\n)/g, '<br />') : '';
    }
}

export class UnorderedListFromArrayValueConverter {
    /**
     * @param value
     *
     * @returns {*}
     */
    convert(value) {
        return '<li>' + value.join('</li><li>') + '</li>';
    }

    /**
     * @param value
     *
     * @returns {string}
     */
    toView(value) {
        return '<li>' + value.join('</li><li>') + '</li>';
    }
}

export class SanitizeHtmlValueConverter {
    /**
     * @param text
     * @param options
     *
     * @returns {*|string}
     */
    sanitizeString(text, options = {}) {
        return !isEmpty(text) ? DOMPurify.sanitize(String(text), options) : '';
    }

    /**
     * @param text
     * @param options
     *
     * @returns string
     */
    toView(text, options = {}) {
        return !isEmpty(text) ? DOMPurify.sanitize(String(text), options) : '';
    }
}

export class NumbersOnlyValueConverter {
    /**
     * @param value
     * @returns {string}
     */
    toView(value) {
        if (value === null || value === undefined || (typeof value !== 'string' && typeof value !== 'number')) {
            return '';
        }

        return value.toString().replace(/\D/g, '');
    }
}
