export class ToFixedValueConverter {

    /**
     *
     * @param value
     * @param decimalPlaces
     *
     * @returns {*}
     */
    toView(value, decimalPlaces = 2) {
        return value.toFixed(decimalPlaces);
    }

}

export class RemoveTrailingZerosValueConverter {

    /**
     *
     * @param value
     *
     * @returns {*}
     */
    toView(value) {
        return Number(value);
    }

}

export class CurrencyValueConverter {

    /**
     *
     * @param value
     *
     * @returns {*}
     */
    toView(value) {
        return `${value} €`;
    }

}

export class AutoDecimalValueConverter {
    toView(value) {
        if (value === null || value === undefined || (typeof value !== 'string' && typeof value !== 'number')) {
            return '';
        }

        value = value.toString();

        value = value.replace(/\D/g, '');

        if (value.length > 3 && !value.includes('.')) {
            const integerPart = value.slice(0, 3);
            const decimalPart = value.slice(3);

            value = `${integerPart}.${decimalPart}`;
        }

        return value;
    }
}
